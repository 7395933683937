/**
 * 商品类型
 * IN_KIND：实物，VIRTUAL_RECHARGE：虚拟产品-直充，E_COUPON_SECRET：电子卡券-卡密，SHOP_COUPON：商超消费券，WX_VOUCHER：微信代金券
 */
export const GOODS_TYPE = {
  IN_KIND: '实物',
  VIRTUAL_RECHARGE: '虚拟产品-直充',
}

/**
 * 商品标签
 * ZX（尊选），TX（特选），HX（惠选）
 */
export const GOODS_TAG = {
  ZX: '尊选',
  TX: '特选',
  HX: '惠选',
}

/**
 * 商品标签
 * ZX（尊选） '#EABA6E'，TX（特选）'#F03E3E'，HX（惠选）'#FFD504'
 */
export const GOODS_TAG_COLOR = {
  尊选: '#EABA6E',
  特选: '#F03E3E',
  惠选: '#FFD504',
}

export const COUPON_TYPE = {
  PRICE_1: 'YUAN_1', // 一元购
  HALF_OFF: 'DISCOUNT_5', // 5折
  FLASH_SALE: 'DISCOUNT', // 秒杀
  EXCHANGE_TICKET: 'EXCHANGE', // 兑换券
  CUTOFF: 'DISCOUNT_MJ' // 满减
}

export const TICKET_TYPE = {
  MEMBER_COUPON: 'MEMBER_COUPON',
  MONTH_COUPON: 'MONTH_COUPON'
}

export const PAY_TYPE = {
  WX_PAY: '微信支付'
}

export const ORDER_STATUS = [{
  label: '待付款',
  value: 'WAIT_PAY'
}, {
  label: '待发货',
  value: 'WAIT_SEND'
}, {
  label: '待收货',
  value: 'WAIT_RECEIVE'
}, {
  label: '已完成',
  value: 'FINISH'
}, {
  label: '已取消',
  value: 'CANCEL'
}]

export const formatOrderStatusLabel = (status) => {
  switch (status)
  {
    case 'WAIT_PAY':
      return '待付款'
    case 'WAIT_SEND':
      return '待发货'
    case 'WAIT_RECEIVE':
      return '已发货'
    case 'FINISH':
      return '交易成功'
    case 'CANCEL':
      return '交易关闭'
  }
}

export const formatOrderDetailStatusLabel = (status) => {
  switch (status)
  {
    case 'WAIT_PAY':
      return '待付款'
    case 'WAIT_SEND':
      return '等待发货'
    case 'WAIT_RECEIVE':
    case 'SENDING':
      return '已发货'
    case 'FINISH':
    case 'FINISHED':
    case 'EXCHANGED':
      return '交易成功'
    case 'CANCEL':
      return '交易关闭'
  }
}

export const formatLogisticsStatus = (statusCode, statusName) => {
  switch (statusCode)
  {
    case '0':
      return '运输中'
    case '103':
      return '已揽件'
    case '1002':
      return ''
    case '303':
      return '待取件'
    case '5':
      return '派送中'
    case '3':
    case '301':
    case '302':
    case '303':
    case '304':
      return '已签收'
  }
  return statusName
}

export const formatLogisticsStatusStyle = (statusCode) => {
  switch (statusCode)
  {
    case '0':
    case '1':
    case '101':
    case '102':
    case '103':
      return 'fill'
    case '1002':
      return ''
    case '303':
      return 'fill'
    case '5':
    case '501':
      return 'fill highlight'
    case '3':
    case '301':
    case '302':
    case '303':
    case '304':
      return 'fill highlight'
  }
  return ''
}

export const formatMemberPrice = (price, memberPrice, coupon) => {
  if (!coupon) {
    return memberPrice
  }
  if (coupon.type === COUPON_TYPE.PRICE_1) {
    return 1
  } else if (coupon.type === COUPON_TYPE.HALF_OFF) {
    return (price / 2).toFixed(2)
  } else if (coupon.type === COUPON_TYPE.FLASH_SALE) {
    const rules = JSON.parse(coupon.discountConfig)
    if (rules.items && rules.items.length > 0) {
      const ruleItems = JSON.parse(JSON.stringify(rules.items))
      ruleItems.sort((a,b) => {
        return a.value > b.value ? 1 : -1
      })
      return (price * ruleItems[0].value/10).toFixed(2)
      // const rule = ruleItems.find(rule => rule.num === item.num)
      // if (rule) {
      //   item.memberPrice = (item.price * rule.value/10).toFixed(2)
      // }
    }
  }
  return memberPrice
}