import axios from 'axios'
import { router } from '@/router'
import store from '@/store'
import storage from 'store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Toast } from 'vant'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    console.log(error.response.data)
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    const { status } = error.response
    if ((status === 400 || status === 403) && !error.config.ignoreError) {
      if (data.title.indexOf('登录手机号与该微信绑定的手机号') !== -1) {
        return Promise.reject(error)
      }
      Toast(data.title, {
        duration: 3000
      })
    }

    if (status === 401) {
      if (token) {
        store.dispatch('Logout').then(() => {
          router.replace({ name: 'login' })
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers[ACCESS_TOKEN] = `Bearer ${token}`
  }
  config.headers['projectCode'] = 'v3'
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  },
}

export default request

export { installer as VueAxios, request as axios }
